import React from "react";
import Heading from "./Common/Heading";
import play from "../images/play_icon.svg";
import uniqueKey from "../images/unique-key.png";
import collaborativeDeck from "../images/collaborative-deck.png";
import gameResilience from "../images/game-resilience.png";
import secureCardDealing from "../images/secure-card-dealing.png";
import { WatchButton } from "./WatchButton";

function SolvingTheMentalPoker() {
  return (
    <>
      <section id="solution" className="max-w-7xl mx-auto pb-5 px-4 xl:px-0">
        <br />  <br />   <br />
        {/* HEADING AND PARA */}
        <div className="space-y-6">
          <div className="w-full">
            <Heading text={"Zero Trust Poker Redefines Online Poker"} />
          </div>
          {/* <div className="w-full">
            <div className="text-[28px] lg:text-1xl leading-[normal] lg:leading-[68px] font-bold lg:font-semibold">
              Solving the Mental Poker Challenge, Zero Trust Poker from Theory to Practice
            </div>
          </div> */}

          <p className="  text-sm leading-6 lg:text-lg tracking-[0.36px] font-bold lg:font-medium">
            Traditional online poker platforms face a challenge: balancing trust with transparency. Centralized servers handling card dealing and game logic raise concerns about manipulation and unfairness.<br></br>
            <br></br>The combination of security, and transparency offered by blockchain technology paves the way for a new era of online poker. Players can experience the thrill of the game with the confidence that every hand is dealt and played fairly.
          </p>
        </div>

        <br></br>
        {/* TEXT */}


        {/* CARDS */}
        <div className=" space-y-4">
          {/* CARD */}
          <div className="relative flex flex-col items-start gap-4 border border-[#c6dade80] hover:border-white-300 bg-[#f6fdff26] backdrop-blur-md rounded-lg p-4 lg:pl-[124px] lg:pr-8 lg:py-8">
            {/* IMAGE WITH TITLE */}
            <div className="flex items-center gap-4">
              {/* IMAGE */}
              <img
                style={{ width: "85px" }}
                className=" lg:absolute lg:top-1/2 lg:left-0 lg:translate-x-8 lg:-translate-y-1/2"
                src={collaborativeDeck}
                alt="collaborative"
              />

              {/* TITLE */}
              <h3 className=" text-xl lg:text-[28px] lg:leading-[normal] text-grey-200 font-bold">
                Collaborative Deck Encryption
              </h3>
            </div>

            {/* PARA */}
            <p className=" text-base text-gray-200">
              Our server starts the game by generating and encrypting a deck of
              cards. Each player adds their layer of security by further
              shuffling and encrypting the deck,{" "}
              <span className=" text-blue-100 font-bold">
                ensuring every card's journey is unpredictable.
              </span>
            </p>
          </div>

          {/* CARD */}
          <div className="relative flex flex-col items-start gap-4 border border-[#c6dade80] hover:border-white-300 bg-[#f6fdff26] backdrop-blur-md rounded-lg p-4 lg:pl-[124px] lg:pr-8 lg:py-8">
            {/* IMAGE WITH TITLE */}
            <div className="flex items-center gap-4">
              {/* IMAGE */}
              <img
                style={{ width: "85px" }}
                className=" lg:absolute lg:top-1/2 lg:left-0 lg:translate-x-8 lg:-translate-y-1/2"
                src={secureCardDealing}
                alt="secure_card"
              />

              {/* TITLE */}
              <h3 className=" text-xl lg:text-[28px] lg:leading-[normal] text-grey-200 font-bold">
                Secure Card Dealing
              </h3>
            </div>

            {/* PARA */}
            <p className=" text-base text-gray-200">
              Players cooperate to decrypt and validate their cards{" "}
              <span className=" text-blue-100 font-bold">
                ensuring privacy and security throughout the game.
              </span>
            </p>
          </div>

          {/* CARD */}
          <div className="relative flex flex-col items-start gap-4 border border-[#c6dade80] hover:border-white-300 bg-[#f6fdff26] backdrop-blur-md rounded-lg p-4 lg:pl-[124px] lg:pr-8 lg:py-8">
            {/* IMAGE WITH TITLE */}
            <div className="flex items-center gap-4">
              {/* IMAGE */}
              <img
                style={{ width: "85px" }}
                className=" lg:absolute lg:top-1/2 lg:left-0 lg:translate-x-8 lg:-translate-y-1/2"
                src={uniqueKey}
                alt="game_resilience"
              />

              {/* TITLE */}
              <h3 className=" text-xl lg:text-[28px] lg:leading-[normal] text-grey-200 font-bold">
                Unique Key for Each Card
              </h3>
            </div>

            {/* PARA */}
            <p className=" text-base text-gray-200">
              This security measure ensures that cards remain confidential,{" "}
              <span className=" text-blue-100 font-bold">
                providing an extra layer of protection and maintaining the integrity of the game.
              </span>

            </p>
          </div>

          {/* CARD */}
          <div className="relative flex flex-col items-start gap-4 border border-[#c6dade80] hover:border-white-300 bg-[#f6fdff26] backdrop-blur-md rounded-lg p-4 lg:pl-[124px] lg:pr-8 lg:py-8">
            {/* IMAGE WITH TITLE */}
            <div className="flex items-center gap-4">
              {/* IMAGE */}
              <img
                style={{ width: "85px" }}
                className=" lg:absolute lg:top-1/2 lg:left-0 lg:translate-x-8 lg:-translate-y-1/2"
                src={gameResilience}
                alt="game_resilience"
              />

              {/* TITLE */}
              <h3 className=" text-xl lg:text-[28px] lg:leading-[normal] text-grey-200 font-bold">
                Game Resilience
              </h3>
            </div>

            {/* PARA */}
            <p className=" text-base text-gray-200">
              Each player securely backs up their key in a decentralized manner,{" "}
              <span className=" text-blue-100 font-bold">
                ensuring that gameplay can continue seamlessly
              </span>
              , even if a player loses connection.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default SolvingTheMentalPoker;
