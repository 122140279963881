import React from "react";

export const OurWhitepaper = () => {

  return (
    <>
      <a href={'/whitepaper.pdf'} target="_blank" rel="noopener noreferrer" className="text-white-100">
        <button className="w-full md:w-fit flex items-center justify-center gap-2 px-4 py-2.5 font-semibold text-white-200 border border-white-200 rounded-full bg-transparent transition-all hover:transition-all hover:shadow-[0_4px_12px_0px_rgba(251,254,255,0.50)] group">
          <span className="text-sm transition-all font-semibold translate-y-0 group-hover:transition-all group-hover:-translate-y-[1.3px]">
            Read our Whitepaper
          </span>
        </button>
      </a>

    </>

  )
}