import React from "react";
import Heading from "./Common/Heading";
import cards from "../images/cards_icon.svg";
import blue_chip from "../images/1_blue_chip.svg";
import red_chip from "../images/2_red_chip.svg";
import green_chip from "../images/3_green_chip.svg";
import { useNavigate } from "../hook/useNavigate";
import { WatchButton } from "./WatchButton";
import { OurWhitepaper } from "./OurWhitepaperButton";

function WeAreNotBank() {
  const { navNewTab } = useNavigate()

  return (
    <>
      <section id="howto" className="max-w-7xl mx-auto pt-2 pb-8 lg:py-20 px-4 xl:px-0">
        <div className="flex flex-col lg:flex-row items-start sm:items-start lg:items-start justify-between gap-14">
          {/* LEFT DETAILS */}
          <div className="space-y-8">
            {/* HEADING AND PARA */}
            <div className="hidden lg:block space-y-6">
              <Heading text="We are not the Bank!" />
              <p className=" text-grey-100 text-base lg:text-lg leading-[normal] lg:leading-6 tracking-[0.36px] font-bold">
                Direct cash in and cash out via your Wallet.
              </p>
            </div>

            {/* BUTTONS */}
            <div className="hidden lg:flex w-full flex flex-row md:flex-row items-center md:items-baseline justify-start gap-4">
              {/* CONNECT BUTTON AND TEXT*/}
              <div className="w-full md:w-fit flex flex-col items-start justify-start">
                {/* CONNECT BUTTON */}
                <button style={{ minWidth: "200px" }} onClick={() => navNewTab('http://play.ztpoker.com')} className="w-full md:w-fit flex items-center justify-center gap-2 px-[25px] py-2.5 text-xs font-semibold text-black-200 border border-blue-100 rounded-full bg-linear-gradient-blue transition-all hover:transition-all hover:shadow-[0_4px_4px_0px_rgba(251,254,255,0.05)inset,0px_4px_12px_0px_rgba(77,222,255,0.50)] group">
                  <span className="transition-all translate-y-0 group-hover:transition-all group-hover:-translate-y-[1.3px]">
                    Play Now
                  </span>
                  <img src={cards} alt="cards" />
                </button>

                {/* NO SIGNUP TEXT */}
                <div className=" text-white-200 text-xs leading-[24.9px] font-medium pt-2.5">
                  <span className=" text-blue-100 font-bold">* </span> No Download/Signup
                  Needed
                </div>
              </div>

              {/* WATCH VIDEO BUTTON */}
              <OurWhitepaper />
            </div>
          </div>

          {/* RIGHT STEPS */}
          <div className="space-y-3 w-full max-w-[592px]">
            {/* HEADING */}
            <h4 className="text_bg_clip text-xl font-bold">How to play Zero Trust Poker:</h4>

            {/* CHIPS */}
            <div className="space-y-2">
              {/* CHIP */}
              <div className="flex items-center gap-4 py-1 pl-1 pr-6 rounded-full border border-[#fbfeff40] hover:border-[#fbfeff8c] bg-[#f6fdff26] backdrop-blur-md hover:shadow-[0px_4px_12px_0px_rgba(77,222,255,0.24)]">
                <img src={blue_chip} alt="chip" />
                <h5 className="text-base leading-[normal] text-white-200 font-bold">
                  Connect Your wallet for Authentication
                </h5>
              </div>

              {/* CHIP */}
              <div className="flex items-center gap-4 py-1 pl-1 pr-6 rounded-full border border-[#fbfeff40] hover:border-[#fbfeff8c] bg-[#f6fdff26] backdrop-blur-md hover:shadow-[0px_4px_12px_0px_rgba(227,41,55,0.24)]">
                <img src={red_chip} alt="chip" />
                <h5 className="text-base leading-[normal] text-white-200 font-bold">
                  Choose Game Table
                </h5>
              </div>

              {/* CHIP */}
              <div className="flex items-center gap-4 py-1 pl-1 pr-6 rounded-full border border-[#fbfeff40] hover:border-[#fbfeff8c] bg-[#f6fdff26] backdrop-blur-md hover:shadow-[0px_4px_12px_0px_rgba(38,210,55,0.24)]">
                <img src={green_chip} alt="chip" />
                <h5 className="text-base leading-[normal] text-white-200 font-bold">
                  Choose amount to play with and start playing
                </h5>
              </div>
            </div>

            <div style={{ marginTop: "60px" }} className="lg:hidden items-center space-y-4">
              <Heading text="We are not the Bank!" />
              <p className=" text-grey-100 text-base lg:text-lg leading-[normal] lg:leading-6 tracking-[0.36px] font-bold">
                Direct cash in and cash out via your Wallet.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WeAreNotBank;
