import React from "react";
import logo from "../images/logo.png";
import discord from "../images/discord.svg";
import twitter from "../images/twitter_icon.svg";
import cards from "../images/cards_icon.svg";
import telegram from "../images/telegram.svg";
import { useNavigate } from "../hook/useNavigate";
import { Link, animateScroll as scroll } from 'react-scroll';

function Navbar() {
  const { navNewTab } = useNavigate()

  const navLinks = [
    {
      goTo: "solution",
      linkTitle: "Solution",
    },
    {
      goTo: "howto",
      linkTitle: "How to?",
    },
    {
      goTo: "faq",
      linkTitle: "FAQ",
    },
    {
      goTo: "roadmap",
      linkTitle: "Roadmap",
    },
  ];

  const socialLinks = [
    {
      goTo: "https://t.me/ztpoker",
      icon: telegram,
    },
    {
      goTo: "https://discord.gg/Y3ncVAeuqc",
      icon: discord,
    },
    {
      goTo: "https://x.com/ZeroTrustPoker",
      icon: twitter,
    },
  ];

  return (
    <>
      <nav className="max-w-7xl mx-auto py-3 lg:py-6 px-4 xl:px-0">
        <div className="flex items-center justify-between">
          {/* LOGO AND LINKS */}
          <div className="flex items-center space-x-8">
            {/* LOGO */}
            <a href="/" className="flex items-center gap-2">
              <img
                style={{ height: '48px', width: '48px' }}
                src={logo}
                alt="Zero Trust Poker Logo"
              />
              <span style={{ fontSize: 32 }} className="text-white-200 font-logo hidden lg:block">
                Zero Trust Poker
              </span>

              <span style={{ fontSize: 16 }} className="text-white-200 font-logo lg:hidden lg:block">
                Zero Trust Poker
              </span>
            </a>

            {/* NAV LINKS */}
            <div className="items-center space-x-4 hidden lg:flex">
              {navLinks.map((item, index) => (
                <a href={item.goTo} className="text-white-100 text-sm" >
                  <Link
                    key={index}
                    activeClass="active"
                    to={item.goTo}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    {item.linkTitle}
                  </Link>
                </a>
              ))}
            </div>
          </div>

          {/* SOCIAL LINKS AND CONNECT BUTTON */}
          <div className="flex items-center space-x-8">
            {/* SOCIAL LINKS */}
            <div className="items-center space-x-3 flex">
              {socialLinks.map((item, index) => (
                <div key={index} style={{ cursor: 'pointer' }} onClick={() => navNewTab(item.goTo)}>
                  <img src={item.icon} alt="icon" />
                </div>
              ))}
            </div>

            {/* CONNECT BUTTON */}
            <button onClick={() => navNewTab('http://play.ztpoker.com')}
              className="hidden lg:flex items-center gap-2 px-4 py-2.5 text-xs font-semibold text-black-200 border border-blue-100 rounded-full bg-linear-gradient-blue transition-all hover:transition-all hover:shadow-[0_4px_4px_0px_rgba(251,254,255,0.05)inset,0px_4px_12px_0px_rgba(77,222,255,0.50)] group">
              <span className="transition-all translate-y-0 group-hover:transition-all group-hover:-translate-y-[1.3px]">Play Now</span>
              <img src={cards} alt="cards" />
            </button>
          </div>
        </div>
      </nav >
    </>
  );
}

export default Navbar;
