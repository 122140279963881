import React from "react";

function Heading(props) {
  return (
    <>
      <h2 className="text_bg_clip text-[28px] lg:text-5xl leading-[normal] lg:leading-[64px] lg:-tracking-[1px] font-bold lg:font-semibold">
        {props.text}
      </h2>
    </>
  );
}

export default Heading;
