import Footer from "./components/Footer";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import OurRoadmap from "./components/OurRoadmap";
import SolvingTheMentalPoker from "./components/SolvingTheMentalPoker";
import WeAreNotBank from "./components/WeAreNotBank";
import ZeroTrustPoker from "./components/ZeroTrustPoker";

function App() {
  return (
    <>
      <Navbar />
      <Header />
      <div className="hacker_bg overflow-hidden">
        <SolvingTheMentalPoker />
        <WeAreNotBank />
        <ZeroTrustPoker />
      </div>
      <OurRoadmap />
      <Footer />
    </>
  );
}

export default App;
