import React, { useState } from "react";
import logo from "../images/logo.png";
import discord from "../images/discord.svg";
import twitter from "../images/twitter_icon.svg";
import telegram from "../images/telegram.svg";
//import copyright from "../images/copyright.svg";
import { useNavigate } from "../hook/useNavigate";
import { Link } from "react-scroll";

function Footer() {
  const { navNewTab } = useNavigate()
  const [contactClicks, setContactClicks] = useState(0);

  const navLinks = [
    {
      goTo: "solution",
      linkTitle: "Solution",
    },
    {
      goTo: "howto",
      linkTitle: "How to?",
    },
    {
      goTo: "faq",
      linkTitle: "FAQ",
    },
    {
      goTo: "roadmap",
      linkTitle: "Roadmap",
    },
    {
      goTo: "mailto:support@zero-trust-poker.com",
      goTo2: "https://mail.google.com/mail/?view=cm&fs=1&to=support@zero-trust-poker.com",
      linkTitle: "Contact us",
    },
    {
      goTo: "/whitepaper.pdf",
      linkTitle: "Whitepaper",
      newTab: true
    },
    {
      goTo: "/game-fees.pdf",
      linkTitle: "Game Fees",
      newTab: true
    },
    {
      goTo: "/terms-and-conditions.pdf",
      linkTitle: "Terms & Conditions",
      newTab: true
    },
    {
      goTo: "/privacy-policy.pdf",
      linkTitle: "Privacy Policy",
      newTab: true
    },

  ];

  const socialLinks = [
    {
      goTo: "https://t.me/ztpoker",
      icon: telegram,
    },
    {
      goTo: "https://discord.gg/Y3ncVAeuqc",
      icon: discord,
    },
    {
      goTo: "https://x.com/ZeroTrustPoker",
      icon: twitter,
    },
  ];

  return (
    <>
      <div className="bg-blue-200 py-8 lg:py-14 px-4 xl:px-0">
        <div className="flex flex-row max-w-7xl items-center mx-auto space-y-4">
          {/* LOGO AND SOCIAL LINKS */}
          <div className="flex flex-col items-center justify-between gap-8">
            {/* LOGO */}
            <a href="/" className="flex items-center gap-2">
              <img width={56} height={56} src={logo} alt="logo" />
              <span className="text-white-200 font-semibold">
                Zero Trust Poker
              </span>
            </a>
            {/* LINKS */}
            <div className="flex items-center gap-6">
              {/* <a
                href="#abc"
                className=" text-[13px] leading-[30.8px] text-white-100"
              >
                Privacy Policy
              </a>

              <a
                href="#abc"
                className=" text-[13px] leading-[30.8px] text-white-100"
              >
                Terms & Conditions
              </a> */}
            </div>
          </div>

          <div style={{ flex: 1, marginLeft: "80px", maxHeight: "100px", width: '400px', maxWidth: "400px" }} className="hidden flex-wrap items-start flex flex-col lg:flex-col lg:items-start lg:flex gap-4">
            {/* NAV LINKS */}
            {navLinks.map((item, index) => (
              item.newTab ? (
                <a href={item.goTo} target="_blank" rel="noopener noreferrer" className="text-white-100 text-sm">
                  {item.linkTitle}
                </a>
              ) :
                item.linkTitle === 'Contact us' ? (
                  <a className="text-white-100 text-sm"
                    rel="noopener noreferrer"
                    target={contactClicks % 2 === 0 ? "" : "_blank"}
                    href={contactClicks % 2 === 0 ? item.goTo : item.goTo2}
                    onClick={() => setTimeout(() => {
                      setContactClicks(contactClicks + 1)
                    }, 500)} >
                    {item.linkTitle}
                  </a>) :
                  (<a href={item.goTo} className="text-white-100 text-sm">
                    <Link
                      key={index}
                      activeClass="active"
                      to={item.goTo}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      {item.linkTitle}
                    </Link>
                  </a>
                  )))}
          </div>


          {/* LINKS AND COPYRIGHT */}
          <div style={{ flex: 1 }} className="flex flex-col lg:flex-col items-end lg:items-end gap-4">

            {/* SOCIAL LINKS */}
            <div className="flex items-center space-x-4">
              {socialLinks.map((item, index) => (
                <div key={index} style={{ cursor: 'pointer' }} onClick={() => navNewTab(item.goTo)}>
                  <img src={item.icon} alt="icon" />
                </div>
              ))}
            </div>

            {/* COPYRIGHT */}
            <div style={{ flex: 1 }} className="flex items-end gap-4">
              <span className=" text-[13px] leading-[30.8px] text-white-200">
                © Zero Trust Poker
              </span>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default Footer;
