import { useCallback } from "react"

export const useNavigate = () => {

  const navNewTab = useCallback((url) => {
    function getQueryParams() {
      const params = {};
      const queryString = window.location.search.slice(1);
      if (queryString) {
        const pairs = queryString.split('&');
        pairs.forEach(pair => {
          const [key, value] = pair.split('=');
          params[key] = decodeURIComponent(value || '');
        });
      }
      return params;
    }

    function constructNewUrl(baseUrl, params) {
      const url = new URL(baseUrl);
      if (Object.keys(params).length > 0) {
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
      }
      return url.toString();
    }

    const currentQueryParams = getQueryParams();
    const newUrl = constructNewUrl(url, currentQueryParams);

    window.open(newUrl, '_blank');

  }, []);

  return { navNewTab }
}