import React from "react";
import Heading from "./Common/Heading";
import mental_poker from "../images/mental_poker.svg";
import secured_cash_game from "../images/secured_cash_game.svg";
import wallet_connection from "../images/wallet_connection.svg";
import network_support from "../images/network_support.svg";
import solena from "../images/solena.svg";
import new_token from "../images/new_token.svg";
import secure_poker from "../images/secure_poker.svg";
import mobile_app from "../images/mobile_app.svg";
import network from "../images/network.svg";
import logo from "../images/logo.png"

function OurRoadmap() {
  return (
    <>
      <div id="roadmap" className="bg-linear-gradient-roadmap overflow-hidden pb-8 lg:pb-[100px]">
        <br></br>

        {/* HEADING AND PARA */}
        <div className="max-w-7xl mx-auto space-y-4 px-4 xl:px-0">
          <Heading text="Our Roadmap" />
          <p className="text-sm lg:text-lg leading-6 tracking-[0.36px] font-bold text-gray-100">
            Please note that no roadmap is final, expect chaos
          </p>
        </div>

        {/* DESKTOP ROADMAP */}
        <div className="hidden lg:block mt-8">
          {/* TOP SECTION */}
          <div className="flex relative after:content-[''] after:absolute after:-bottom-[2.5px] after:w-full after:h-1.5 after:opacity-[0.1] after:bg-linear-gradient-roadmap-line">
            <div className="w-1/4 h-[247px] flex items-end justify-center pb-8">
              <span className=" text-sm leading-4 text-grey-100">
                Q2 - Q4 2023
              </span>
            </div>

            <div className="w-1/4 h-[247px] relative border-l border-blue-100 flex flex-col justify-between after:content-[''] after:absolute after:-bottom-2 after:-left-2 after:w-4 after:h-4 after:rounded-full after:bg-blue-100 after:shadow-[0px_0px_0px_8px_rgba(77,222,255,0.09)]">
              <div className="pl-4 space-y-4">
                <h6 className="text_bg_roadmap text-lg leading-6 tracking-[0.36px] font-bold">
                  Launch
                </h6>
                <div>
                  <div className="flex items-center gap-1">
                    <img src={secured_cash_game} alt="secured-cash-game" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Secured Texas Holdem Cash Game
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <img src={wallet_connection} alt="wallet-connection" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Wallet connections
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <img src={network_support} alt="network-support" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Arbitrum Network Support
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center pb-8">
                <span className=" text-sm leading-4 text-grey-100">
                  End Q3 2024
                </span>
              </div>
            </div>

            <div className="w-1/4 h-[247px] relative border-l border-blue-100 flex flex-col justify-between after:content-[''] after:absolute after:-bottom-2 after:-left-2 after:w-4 after:h-4 after:rounded-full after:bg-blue-100 after:shadow-[0px_0px_0px_8px_rgba(77,222,255,0.09)]">
              <div className="pl-4 space-y-4">
                <h6 className="text_bg_roadmap text-lg leading-6 tracking-[0.36px] font-bold">
                  Milestone 3
                </h6>
                <div>
                  <div className="flex items-center gap-1">
                    <img src={mobile_app} alt="new-token" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Full Mobile Support
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <img src={secured_cash_game} alt="secure-poker" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Secured Omaha Cash Game
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center pb-8">
                <span className=" text-sm leading-4 text-grey-100">
                  Q1  2026
                </span>
              </div>
            </div>

            <div className="w-1/4 h-[247px] relative border-l border-blue-100 flex flex-col justify-between after:content-[''] after:absolute after:-bottom-2 after:-left-2 after:w-4 after:h-4 after:rounded-full after:bg-blue-100 after:shadow-[0px_0px_0px_8px_rgba(77,222,255,0.09)]">
              <div className="pl-4 space-y-4">
                <h6 className="text_bg_roadmap text-lg leading-6 tracking-[0.36px] font-bold">
                  Milestone 5
                </h6>
                <div>
                  <div className="flex items-center gap-1">
                    <img src={secured_cash_game} alt="network" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Zero Trust Casino
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* BOTTOM SECTION */}
          <div className="flex">
            <div className="w-[12.5%] h-[145px] relative border-r border-blue-100 before:content-[''] before:absolute before:-top-[1.5px] before:w-full before:h-full before:border-t-2 before:border-blue-100 before:border-dashed after:content-[''] after:absolute after:-top-2 after:-right-2 after:w-4 after:h-4 after:rounded-full after:bg-blue-100"></div>

            <div className="w-1/4 h-[145px] flex flex-col justify-between">
              <div className="flex items-center justify-center pt-8">
                <span className=" text-sm leading-4 text-grey-100">
                  Mid Q2 2024
                </span>
              </div>
              <div className="pl-4 space-y-4">

                <div className="flex items-center gap-1">
                  <img style={{ width: 16 }} src={logo} alt="mental-poker" />
                  <span className=" text-[13px] leading-[30.8px] text-grey-100">
                    Mental Poker Pilot
                  </span>
                </div>
              </div>
            </div>

            <div className="w-1/4 h-[145px] relative flex flex-col justify-between border-l border-blue-100 after:content-[''] after:absolute after:-top-2 after:-left-2 after:w-4 after:h-4 after:rounded-full after:bg-blue-100 after:shadow-[0px_0px_0px_8px_rgba(77,222,255,0.09)]">
              <div className="flex items-center justify-center pt-8">
                <span className=" text-sm leading-4 text-grey-100">
                  Q2 2025
                </span>
              </div>
              <div className="pl-4 space-y-4">
                <h6 className="text_bg_roadmap text-lg leading-6 tracking-[0.36px] font-bold">
                  Milestone 2
                </h6>
                <div className="flex items-center gap-1">
                  <img src={secured_cash_game} alt="secured_cash_game" />
                  <span className=" text-[13px] leading-[30.8px] text-grey-100">
                    Secured Texas Holdem Tournaments
                  </span>
                </div>
              </div>
            </div>

            <div className="w-1/4 h-[188px] relative flex flex-col justify-between border-l border-blue-100 after:content-[''] after:absolute after:-top-2 after:-left-2 after:w-4 after:h-4 after:rounded-full after:bg-blue-100 after:shadow-[0px_0px_0px_8px_rgba(77,222,255,0.09)]">
              <div className="flex items-center justify-center pt-8">
                <span className=" text-sm leading-4 text-grey-100">
                  2027
                </span>
              </div>
              <div className="pl-4 space-y-4">
                <h6 className="text_bg_roadmap text-lg leading-6 tracking-[0.36px] font-bold">
                  Milestone 4
                </h6>
                <div>
                  <div className="flex items-center gap-1">
                    <img src={new_token} alt="mobile-app" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Token Launch
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <img src={network} alt="network" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      24/7 Global Launch
                    </span>
                  </div>
                </div>

              </div>
            </div>

            <div className="w-[12.5%] h-[145px]"></div>
          </div>
        </div>

        {/* MOBILE ROADMAP */}
        <div className="lg:hidden px-4 mt-8">
          <div className="px-2 pb-2 relative before:content-[''] before:absolute before:bottom-0 before:top-0 before:left-[7.5px] before:w-1 before:h-full before:opacity-[0.1] before:bg-linear-gradient-roadmap-line">

            <div className="h-[66px] border-l-2 border-blue-100 border-dashed"></div>

            <div className="relative mb-6 border-l-2 border-blue-100 mt-1 pl-3 before:content-[''] before:absolute before:top-0 before:-left-2 before:w-4 before:h-4 before:rounded-full before:bg-blue-100">
              <span className=" text-sm leading-4 text-grey-100 relative -top-1">
                Q2 - Q4 2023
              </span>
              <div className="space-y-2 mt-1">
                <div className="flex items-center gap-1">
                  <img style={{ width: 16 }} src={logo} alt="mental-poker" />
                  <span className=" text-[13px] leading-[30.8px] text-grey-100">
                    Mental Poker Pilot
                  </span>
                </div>
              </div>
            </div>

            <div className="relative mb-6 border-l-2 border-blue-100 mt-1 pl-3 before:content-[''] before:absolute before:top-0 before:-left-2 before:w-4 before:h-4 before:rounded-full before:bg-blue-100">
              <span className=" text-sm leading-4 text-grey-100 relative -top-1">
                Launch
              </span>
              <div className="space-y-2 mt-1">
                <h6 className="text_bg_roadmap text-lg leading-6 tracking-[0.36px] font-bold">
                  Launch
                </h6>
                <div>
                  <div className="flex items-center gap-1">
                    <img src={secured_cash_game} alt="secured-cash-game" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Secured Texas Holdem Cash Game
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <img src={wallet_connection} alt="wallet-connection" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Wallet connections
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <img src={network_support} alt="network-support" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Arbitrum Network Support
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mb-6 border-l-2 border-blue-100 mt-1 pl-3 before:content-[''] before:absolute before:top-0 before:-left-2 before:w-4 before:h-4 before:rounded-full before:bg-blue-100">
              <span className=" text-sm leading-4 text-grey-100 relative -top-1">
                End Q3 2024
              </span>
              <div className="space-y-2 mt-1">
                <h6 className="text_bg_roadmap text-lg leading-6 tracking-[0.36px] font-bold">
                  Milestone 2
                </h6>
                <div className="flex items-center gap-1">
                  <img src={secured_cash_game} alt="secured_cash_game" />
                  <span className=" text-[13px] leading-[30.8px] text-grey-100">
                    Secured Texas Holdem Tournaments
                  </span>
                </div>
              </div>
            </div>

            <div className="relative mb-6 border-l-2 border-blue-100 mt-1 pl-3 before:content-[''] before:absolute before:top-0 before:-left-2 before:w-4 before:h-4 before:rounded-full before:bg-blue-100">
              <span className=" text-sm leading-4 text-grey-100 relative -top-1">
                Q1 2025
              </span>
              <div className="space-y-2 mt-1">
                <h6 className="text_bg_roadmap text-lg leading-6 tracking-[0.36px] font-bold">
                  Milestone 3
                </h6>
                <div>
                  <div className="flex items-center gap-1">
                    <img src={mobile_app} alt="new-token" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Full Mobile Support
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <img src={secured_cash_game} alt="secure-poker" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Secured Omaha Cash Game
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mb-6 border-l-2 border-blue-100 mt-1 pl-3 before:content-[''] before:absolute before:top-0 before:-left-2 before:w-4 before:h-4 before:rounded-full before:bg-blue-100">
              <span className=" text-sm leading-4 text-grey-100 relative -top-1">
                Q1 2026
              </span>
              <div className="space-y-2 mt-1">
                <h6 className="text_bg_roadmap text-lg leading-6 tracking-[0.36px] font-bold">
                  Milestone 4
                </h6>
                <div>
                  <div className="flex items-center gap-1">
                    <img src={new_token} alt="mobile-app" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Token Launch
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <img src={network} alt="secure-poker" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      24/7 Global Launch
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative border-l-2 border-blue-100 mt-1 pl-3 before:content-[''] before:absolute before:top-0 before:-left-2 before:w-4 before:h-4 before:rounded-full before:bg-blue-100">
              <span className=" text-sm leading-4 text-grey-100 relative -top-1">
                2027
              </span>
              <div className="space-y-2 mt-1">
                <h6 className="text_bg_roadmap text-lg leading-6 tracking-[0.36px] font-bold">
                  Milestone 5
                </h6>
                <div>
                  <div className="flex items-center gap-1">
                    <img src={secured_cash_game} alt="network" />
                    <span className=" text-[13px] leading-[30.8px] text-grey-100">
                      Zero Trust Casino
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default OurRoadmap;
