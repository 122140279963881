import React from "react";
import diamond_ace from "../images/diamond_ace.svg";
import diamond_ace_grey from "../images/diamond_ace_grey.svg";
import club_ace from "../images/club_ace.svg";
import club_ace_grey from "../images/club_ace_grey.svg";
import spade_ace from "../images/spade_ace.svg";
import spade_ace_grey from "../images/spade_ace_grey.svg";
import heart_ace from "../images/heart_ace.svg";
import heart_ace_grey from "../images/heart_ace_grey.svg";
import lock_red from "../images/lock_red.svg";
import lock_grey from "../images/lock_grey.svg";
import lock_blue from "../images/lock_blue.svg";
import lock_blue_big from "../images/lock_blue_big.svg";
import Heading from "./Common/Heading";
import { WatchButton } from "./WatchButton";
import { OurWhitepaper } from "./OurWhitepaperButton";

function ZeroTrustPoker() {
  return (
    <>
      <section id="faq" className="max-w-7xl mx-auto px-4 xl:px-0">

        {/* HEADING AND PARA */}
        <div className="hidden lg:block max-w-[864px] space-y-6">
          <Heading
            text={
              "FAQ"
            }
          />

          <p className="hidden lg:block text-grey-100 text-base lg:text-lg leading-[normal] lg:leading-6 tracking-[0.36px] font-bold">
            The Only Game Where You Can Trust the Cards
          </p>
        </div>

        {/* DESKTOP CARDS */}
        <div className="hidden sm:grid grid-cols-2 lg:grid-cols-4 gap-[18px] pt-8">
          {/* FLIP CARD */}
          <div className="flip-card">
            {/* FLIP CARD INNER */}
            <div className="flip-card-inner">
              {/* FLIP CARD FRONT */}
              <div className="flip-card-front flex flex-col justify-between p-3 rounded-xl border border-[#fbfeff59] bg-[#f6fdff26] backdrop-blur-xl shadow-[0_4px_24px_0px_rgba(77,222,255,0.10)]">
                {/* DIAMOND ACE IMG */}
                <img
                  className="absolute top-3 left-3"
                  width={21}
                  height={60}
                  src={diamond_ace}
                  alt="diamond-ace"
                />

                {/* DETAILS */}
                <div className="px-3.5 pt-[158px] w-full text-left">
                  <h3 className="text_bg_clip text-[28px] leading-[normal] font-bold">
                    who can you trust when dealing with money online?
                  </h3>
                  {/*   Removed 2nd row   <p className=" text-base font-medium text-grey-100 pt-5">
                    Enjoy a level playing field, free from manipulation.
                  </p>*/}
                </div>

                {/* LOCK IMG */}
                <img
                  className="absolute bottom-3 right-3"
                  width={48}
                  height={48}
                  src={lock_red}
                  alt="lock-red"
                />
              </div>

              {/* FLIP CARD BACK */}
              <div className="flip-card-back flex flex-col justify-between p-3 rounded-xl border border-[#fbfeff59] bg-[#f6fdff26] backdrop-blur-xl shadow-[0_4px_24px_0px_rgba(77,222,255,0.10)]">
                {/* DIAMOND ACE IMG */}
                <img
                  className="ml-auto"
                  width={21}
                  height={60}
                  src={diamond_ace_grey}
                  alt="diamond-ace-grey"
                />
                {/* DETAILS */}
                <div className="px-3.5 text-left">
                  <p className=" text-lg leading-[28.2px] tracking-[0.36px] font-medium text-grey-100">
                    Trust no one! Verify independently through our Mental Poker Security Protocols.
                  </p>
                </div>
                {/* LOCK IMG */}
                <img width={48} height={48} src={lock_grey} alt="lock-grey" />
              </div>
            </div>
          </div>
          {/* FLIP CARD */}
          <div className="flip-card">
            {/* FLIP CARD INNER */}
            <div className="flip-card-inner">
              {/* FLIP CARD FRONT */}
              <div className="flip-card-front flex flex-col justify-between p-3 rounded-xl border border-[#fbfeff59] bg-[#f6fdff26] backdrop-blur-xl shadow-[0_4px_24px_0px_rgba(77,222,255,0.10)]">
                {/* CLUB ACE IMG */}
                <img
                  className="absolute top-3 left-3"
                  width={23.4}
                  height={54}
                  src={club_ace}
                  alt="club-ace"
                />

                {/* DETAILS */}
                <div className="px-3.5 pt-[158px] w-full text-left">
                  <h3 className="text_bg_clip text-[28px] leading-[normal] font-bold">
                    Are your funds safe?
                  </h3>
                </div>

                {/* LOCK IMG */}
                <img
                  className="absolute bottom-3 right-3"
                  width={48}
                  height={48}
                  src={lock_blue}
                  alt="lock-blue"
                />
              </div>

              {/* FLIP CARD BACK */}
              <div className="flip-card-back flex flex-col justify-between p-3 rounded-xl border border-[#fbfeff59] bg-[#f6fdff26] backdrop-blur-xl shadow-[0_4px_24px_0px_rgba(77,222,255,0.10)]">
                {/* CLUB ACE IMG */}
                <img
                  className="ml-auto"
                  width={23.4}
                  height={54}
                  src={club_ace_grey}
                  alt="club-ace-grey"
                />
                {/* DETAILS */}
                <div className="px-3.5 text-left">
                  <p className=" text-lg leading-[28.2px] tracking-[0.36px] font-medium text-grey-100">
                    Yes! Your funds are secured in smart contracts, allowing for instant cash-ins and cash-outs with no long-term deposits.
                  </p>
                </div>
                {/* LOCK IMG */}
                <img width={48} height={48} src={lock_grey} alt="lock-grey" />
              </div>
            </div>
          </div>
          {/* FLIP CARD */}
          <div className="flip-card">
            {/* FLIP CARD INNER */}
            <div className="flip-card-inner">
              {/* FLIP CARD FRONT */}
              <div className="flip-card-front flex flex-col justify-between p-3 rounded-xl border border-[#fbfeff59] bg-[#f6fdff26] backdrop-blur-xl shadow-[0_4px_24px_0px_rgba(77,222,255,0.10)]">
                {/* HEART ACE IMG */}
                <img
                  className="absolute top-3 left-3"
                  width={26.2}
                  height={54}
                  src={heart_ace}
                  alt="heart-ace"
                />

                {/* DETAILS */}
                <div className="px-3.5 pt-[158px] w-full text-left">
                  <h3 className="text_bg_clip text-[28px] leading-[normal] font-bold">
                    Can others see your cards?
                  </h3>
                </div>

                {/* LOCK IMG */}
                <img
                  className="absolute bottom-3 right-3"
                  width={48}
                  height={48}
                  src={lock_red}
                  alt="lock-red"
                />
              </div>

              {/* FLIP CARD BACK */}
              <div className="flip-card-back flex flex-col justify-between p-3 rounded-xl border border-[#fbfeff59] bg-[#f6fdff26] backdrop-blur-xl shadow-[0_4px_24px_0px_rgba(77,222,255,0.10)]">
                {/* HEART ACE IMG */}
                <img
                  className="ml-auto"
                  width={26.2}
                  height={54}
                  src={heart_ace_grey}
                  alt="heart-ace-grey"
                />
                {/* DETAILS */}
                <div className="px-3.5 text-left">
                  <p className=" text-lg leading-[28.2px] tracking-[0.36px] font-medium text-grey-100">
                    No, our advanced security ensures your cards are visible only to you.
                  </p>
                </div>
                {/* LOCK IMG */}
                <img width={48} height={48} src={lock_grey} alt="lock-grey" />
              </div>
            </div>
          </div>
          {/* FLIP CARD */}
          <div className="flip-card">
            {/* FLIP CARD INNER */}
            <div className="flip-card-inner">
              {/* FLIP CARD FRONT */}
              <div className="flip-card-front flex flex-col justify-between p-3 rounded-xl border border-[#fbfeff59] bg-[#f6fdff26] backdrop-blur-xl shadow-[0_4px_24px_0px_rgba(77,222,255,0.10)]">
                {/* SPADE ACE IMG */}
                <img
                  className="absolute top-3 left-3"
                  width={21.9}
                  height={54}
                  src={spade_ace}
                  alt="spade-ace"
                />

                {/* DETAILS */}
                <div className="px-3.5 pt-[158px] w-full text-left">
                  <h3 className="text_bg_clip text-[28px] leading-[normal] font-bold">
                    What is our mission?
                  </h3>
                </div>

                {/* LOCK IMG */}
                <img
                  className="absolute bottom-3 right-3"
                  width={48}
                  height={48}
                  src={lock_blue}
                  alt="lock-blue"
                />
              </div>

              {/* FLIP CARD BACK */}
              <div className="flip-card-back flex flex-col justify-between p-3 rounded-xl border border-[#fbfeff59] bg-[#f6fdff26] backdrop-blur-xl shadow-[0_4px_24px_0px_rgba(77,222,255,0.10)]">
                {/* SPADE ACE IMG */}
                <img
                  className="ml-auto"
                  width={21.9}
                  height={54}
                  src={spade_ace_grey}
                  alt="spade-ace-grey"
                />
                {/* DETAILS */}
                <div className="px-3.5 text-left">
                  <p className=" text-lg leading-[28.2px] tracking-[0.36px] font-medium text-grey-100">
                    To revolutionize online poker with the highest standards for fair and honest gameplay
                  </p>
                </div>
                {/* LOCK IMG */}
                <img width={48} height={48} src={lock_grey} alt="lock-grey" />
              </div>
            </div>
          </div>
          <br /> <br />
        </div>

        {/* BUTTON */}
        <div className="flex items-center justify-center mt-4">
          {/* WATCH VIDEO BUTTON */}
          <OurWhitepaper />
        </div>

        <br /> <br />
      </section >
    </>
  );
}

export default ZeroTrustPoker;
